import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wrapper from "../components/Resultados/wrapper"
import { Router } from "@reach/router"

import Propiedad from '../templates/propiedad'

const IndexPage = () => (
  <Router basepath="/propiedad">
    <Propiedad path="/:operation/:id" />
  </Router>
)

export default IndexPage